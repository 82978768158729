/* eslint-disable @next/next/no-img-element */
import Head from 'next/head';
import Script from 'next/script';
import { FC, ReactNode } from 'react';

import { DomainInfo } from '_backend/integration/domain-info';
import { AlternateLinks } from '_components/AlternateLinks';
import { LinkDetails } from '_features/longtail/utils';

type PageHeadProps = {
  children?: ReactNode;
  showAlternates?: boolean;
  alternateLinks?: LinkDetails[] | undefined;
  canonical?: string;
  query?: string;
  origin?: string;
  locale?: string;
};

export const PageHead: FC<PageHeadProps> = (props) => {
  const { children, showAlternates = false, canonical, query = '', origin, alternateLinks, locale = 'en' } = props;
  const domainInfo = DomainInfo.getFromOrigin(origin || 'https://www.sortlist.com');
  const iso31661 = domainInfo?.getIso31661();

  return (
    <>
      <Script async src={`https://secure.leadforensics.com/js/${process.env.NEXT_PUBLIC_LEADFORENSICS_ID}.js`} />
      <Head>
        <noscript>
          <img
            className="hidden"
            src={`https://secure.leadforensics.com/${process.env.NEXT_PUBLIC_LEADFORENSICS_ID}.png`}
            alt="leadforensics"
          />
        </noscript>

        {showAlternates && origin && canonical && (
          <AlternateLinks
            locale={locale}
            canonical={canonical}
            query={query}
            alternateLinks={alternateLinks}
            origin={origin}
            iso31661={iso31661 ?? undefined}
          />
        )}
        {children ? children : null}
      </Head>
    </>
  );
};

PageHead.defaultProps = {};
